<template>
  <div id="app">
    <header>
      <h1>发货系统</h1>
      <nav>
        <router-link to="/delivery">发货</router-link>
        <router-link to="/generate-url">生成URL</router-link>
        <router-link to="/query-order">查询订单</router-link>
      </nav>
    </header>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style scoped>
/* Header */
header {
  background-color: #f2f2f7; /* Apple Human Interface Guidelines: subtle background colors */
  padding: 20px;
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

h1 {
  font-size: 24px; /* Clear, readable font size for headers */
  font-weight: 600;
  margin: 0;
  color: #1d1d1f; /* Apple-like dark text */
}

/* Navigation Links */
nav {
  margin-top: 20px;
}

nav a {
  color: #007aff; /* Apple's primary blue color */
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  margin: 0 15px;
  padding: 10px;
}

nav a:hover {
  background-color: #e5e5ea; /* Slight background change on hover */
  border-radius: 8px;
}

nav a.router-link-exact-active {
  color: #1d1d1f;
  font-weight: 600;
  border-bottom: 2px solid #007aff; /* Indicating the active tab */
}
</style>
